<template>
	<div class="h-100">
		<div class="d-flex align-items-center h-100 text-center px-3">
			<div>
				<img src="../assets/svg/motoboy-03.svg" style="max-width: 100%; max-height: 255px" class="mb-3" />
				<h5>Regras de Uso</h5>
				<p>Para continuar você precisa aceitar as Regras de Uso. Nelas você pode encontrar todas as informações importantes relacionadas ao reembolso.</p>
			</div>
		</div>
		<ifood-footer class="p-3">
			<router-link :to="{ name: 'Rules' }" class="btn btn-primary btn-lg btn-block">Ok, entendi!</router-link>
		</ifood-footer>
	</div>
</template>

<script>
import ifoodFooter from "@/components/Footer.vue";
export default {
	name: 'Rules',
	components: {
		ifoodFooter
	},
	data() {
		return {
		}
	},
	created(){
		/*
		
        const configuration = new FasterConfiguration(
          "c352c9f1-6120-4ef5-87e3-c5cec5eb1cda",
          "oIwfANqzIAItf97FHJulmwvN"
        );
        Faster.configure(configuration);
        const fasterAnalyticsInstance = Faster.start();
        const eventBuilder = new EventBuilder("View_CovidRefund_Empty", 0);
        const event_faster = eventBuilder
          .id(localStorage.getItem("id"))
          .externalId(localStorage.getItem("worker_id"))
          .dimension("driverId", localStorage.getItem("worker_id"))
          .dimension("source", "app")
          .build();
        fasterAnalyticsInstance.event.register(event_faster);
        //End
        //amplitude
        var userId = localStorage.getItem("worker_id");
        amplitude.getInstance().init("acd3720cc16f656e0f48bf417916784d", userId);
        var event = "View_CovidRefund_Empty";
        var eventProperties = {
          driver_Id: localStorage.getItem("worker_id"),
          driver_uuid: localStorage.getItem("id"),
          source: "app",
          timestamp: Date.now(),
        };
        amplitude.getInstance().logEvent(event, eventProperties);
		*/
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
footer {
	background-color: $white;
}
h1 {
	font-size: 18px;
}

p {
	color: #717171;
}
</style>